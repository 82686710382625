/*
## PageTop
*/

BLISSWORLD.PageTop = Backbone.View.extend({
  
  initialize: function(opts){
    _.bindAll(this, "scroll");
    BLISSWORLD.$window.scroll(this.scroll);
  },
  
  scroll: function(){
    var $pageTop = $('.pagetop_1');
    
    var move = function() {
      var scrollHeight = $(document).height();
      var scrollPosition = $(window).height() + $(window).scrollTop();
      var windowWidth = $(window).width();
      var posiStop = $('.g_footer_1').innerHeight();

      if(windowWidth > 768) {
        if ($(window).scrollTop() > 400) {
          $pageTop.fadeIn();
        } else {
          $pageTop.fadeOut();
        }
        
        if (scrollHeight - scrollPosition <= posiStop) {
          console.log(posiStop);
          $pageTop.addClass('is-stop');
        } else {
          $pageTop.removeClass('is-stop');
        }
      }
    }
    
    move();
  }
  
});