BLISSWORLD.GlobalHeader_A = Backbone.View.extend({
  
  initialize: function() {
    console.log("GlobalHeader_A");
    var self = this;
    _.bindAll(this, "resize", "scroll", "toggle", "open", "close");
    this.opts = {
      duration: 400
    };
    
    this.status = {
      top: false,
      topPrev: true
    };
    
    this.opts.style2 = (this.$el.hasClass("is_style2"))?false:true;
    //this.opts.style2 = false;
    console.log("this.opts.style2", this.opts.style2);
    
    this.$style1 = this.$(".g_header_1__style1");
    this.$style2 = this.$(".g_header_1__style2");
    
    this.$gnav = $(".g_nav_1");
    this.$toggle = this.$(".js_accordion__toggle");
    this.$close = this.$(".js_accordion__close");
    
    this.target = this.$toggle.attr("href");
    this.duration = 1000;
    this.easing = "easeInOutExpo";
    this.$target = $( this.target.replace("#", "") );
    
    this.opened = false;
    
    if( this.$el.hasClass("is_active") ){
    } else {
      this.$target.css("display", "none");
    }
    
    this.$toggle.click(this.toggle);
    this.$close.click(this.close);
    
    //
    if( this.opts.style2 ){
      this.resize();
      BLISSWORLD.$window.resize(this.resize);
      
      var top = BLISSWORLD.$document.scrollTop();
      console.log("$document");
      this.status.top = (this.top > top)?true:false;
      if( this.status.top ) {
        this.$el.removeClass("is_style2");
        this.$style1.css("display", "block");
        this.$style2.css("display", "none");
      } else {
        this.$el.addClass("is_style2");
        this.$style1.css("display", "none");
        this.$style2.css("display", "block");
      }
      
      BLISSWORLD.$window.scroll(this.scroll);
    } else {
      this.$style1.css("display", "none");
      this.$style2.css("display", "block");
    }
    
  },
  
  resize: function(){
    if( this.$gnav.length ){
      this.top = this.$gnav.offset().top;
    } else {
      this.top = 0;
    }
    this.scroll();
  },
  
  scroll: function() {
    var self = this,
        top = BLISSWORLD.$document.scrollTop();
    
    this.status.top = (this.top > top)?true:false;
    
    if( this.status.top != this.status.topPrev ) {
      this.status.topPrev = this.status.top;
      if( this.status.top ) {
        this.$el.removeClass("is_style2");
        self.$style2
          .stop(true,false)
          .fadeOut(self.opts.duration, function(){
            self.$style1.stop(true,false).fadeIn(self.opts.duration + 200);
          });
      } else {
        this.$el.addClass("is_style2");
        self.$style1
          .stop(true,false)
          .fadeOut(self.opts.duration, function(){
            self.$style2.stop(true,false).fadeIn(self.opts.duration + 200);
          });
      }
    }
  },
  
  toggle: function(e) {
    console.log("toggle");
    e.preventDefault();
    if( this.opened ){
      this.close();
    } else {
      this.open();
    }
  },
  
  open: function(){
    console.log("open");
    //this.$target.css("top", BLISSWORLD.$document.scrollTop());
    this.$target.slideDown(this.duration, this.easing);
    BLISSWORLD.$body.addClass("is_open_spnav");
    this.$toggle.addClass("is_active");
    this.opened = true;
    return false;
  },
  
  close: function(){
    console.log("close");
    this.$target.slideUp(this.duration, this.easing);
    BLISSWORLD.$body.removeClass("is_open_spnav");
    this.$toggle.removeClass("is_active");
    this.opened = false;
    return false;
  }
  
});