BLISSWORLD.Anchor_A = function(){
  console.group("Anchor_A");
  
  var hash = window.location.hash;
  console.log("hash", hash);
  
  if( !hash ) return;
  if( 0 > hash.indexOf("#.") ) return;
  
  var klass = hash.replace("#", ""),
      $klass = $(klass);
  console.log("klass", klass);
  
  var top = Math.round( $klass.offset().top );
  console.log("top", top);
  
  //BLISSWORLD.$window.scrollTop(top);
  BLISSWORLD.$html_body.stop(true,false).animate({
    scrollTop: top
  }, 1000, "easeOutExpo");
  
  console.groupEnd();
}