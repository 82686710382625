$(function(){
  
  BLISSWORLD.$window = $(window);
  BLISSWORLD.$document = $(document);
  BLISSWORLD.$html_body = $("html, body");
  BLISSWORLD.$body = $("body");
  
  
  new BLISSWORLD.GlobalHeader_A({
    el: ".g_header_1"
  });
  
  new BLISSWORLD.PageTop({
    el: ".pagetop_1"
  });
  
  $(".js_smoothScroll_1").each(function(index, el){
    new BLISSWORLD.SmoothScroll({
      el: el
    });
  });
  
  
  BLISSWORLD.$window.on("load", function(){
    
    new BLISSWORLD.Anchor_A();
    
  });
  
  
});