/*
## SmoothScroll
*/

BLISSWORLD.SmoothScroll = Backbone.View.extend({
  
  initialize: function(opts){
    _.bindAll(this, "click");
    this.opts = _.extend({
      target: '.js_smoothScroll_1'
    }, opts);
    
    this.anchor = this.$el.attr("data-anchor");
    this.$anchor = $(this.anchor);
    //this.top = Math.round( this.$anchor.offset().top );
    
    this._id = this.$anchor.attr("id");
    if( this._id ){
      this.$el.attr("id", this._id);
    }
    
    if( this.$anchor.length ){
      this.$el.click(this.click);
    }
  },
  
  click: function(e){
    e.preventDefault();
    this.top = Math.round( this.$anchor.offset().top );
    BLISSWORLD.$html_body.stop(true,false).animate({
      scrollTop: this.top
    }, 1000, "easeOutExpo");
  }
  
});
